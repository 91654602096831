import React from 'react';

export const PdfIcon = ({ size = 36 }: { size?: number }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 49 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_559_5717)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49 16.842V58.947C49 61.738 46.73 64 43.931 64H5.069C2.269 64 0 61.738 0 58.947V5.053C0 2.262 2.269 0 5.069 0H32.103L49 16.842Z"
          fill="#CB0606"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.0001 15.899V17.995H35.1871C32.3271 17.995 31.0081 15.675 31.0081 12.814V0H33.1001L49.0001 15.899Z"
          fill="#FB8D8D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.606 34.047C37.17 33.903 36.75 33.83 36.345 33.83C35.885 33.83 35.569 33.925 35.396 34.115C35.224 34.305 35.138 34.641 35.138 35.124V36.047H37.071V37.901H35.138V45.536H32.796V37.901H31.589V36.047H32.796V35.124C32.796 33.948 33.088 33.098 33.672 32.573C34.256 32.048 35.134 31.786 36.308 31.786C36.859 31.786 37.443 31.861 38.06 32.012L37.606 34.047ZM27.796 44.613C27.36 44.981 26.9 45.274 26.416 45.491C25.932 45.708 25.382 45.816 24.765 45.816C23.567 45.816 22.65 45.385 22.015 44.523C21.379 43.66 21.062 42.442 21.062 40.868C21.062 39.258 21.411 38.007 22.11 37.114C22.809 36.222 23.79 35.775 25.055 35.775C25.503 35.775 25.962 35.867 26.434 36.051C26.906 36.235 27.297 36.469 27.605 36.752V32.066H29.947V45.536H27.796V44.613ZM27.605 38.679C27.357 38.456 27.05 38.258 26.684 38.086C26.318 37.915 25.944 37.829 25.563 37.829C24.934 37.829 24.429 38.099 24.048 38.638C23.666 39.178 23.476 39.909 23.476 40.832C23.476 41.743 23.632 42.457 23.943 42.976C24.255 43.495 24.737 43.754 25.391 43.754C25.778 43.754 26.173 43.665 26.575 43.487C26.977 43.309 27.321 43.09 27.605 42.831V38.679ZM17.595 45.201C17.02 45.611 16.334 45.816 15.535 45.816C14.482 45.816 13.584 45.488 12.84 44.83V49.109H10.498V36.047H12.658V36.978C13.094 36.604 13.552 36.31 14.033 36.096C14.514 35.882 15.066 35.775 15.689 35.775C16.893 35.775 17.81 36.197 18.439 37.042C19.068 37.886 19.383 39.113 19.383 40.723C19.383 41.743 19.229 42.641 18.92 43.419C18.611 44.197 18.17 44.791 17.595 45.201ZM16.488 38.602C16.167 38.086 15.689 37.829 15.054 37.829C14.673 37.829 14.281 37.918 13.879 38.095C13.476 38.273 13.13 38.492 12.84 38.751V42.903C13.088 43.127 13.395 43.324 13.761 43.496C14.127 43.668 14.503 43.754 14.891 43.754C15.532 43.754 16.039 43.481 16.411 42.935C16.783 42.389 16.969 41.664 16.969 40.76C16.969 39.837 16.808 39.118 16.488 38.602Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_559_5717">
          <rect width="49" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
